import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './assets/MyFontsWebfontsKit.css';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './components/App/App';
import { store } from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';


ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider>
            <App/>
        </MuiThemeProvider>
    </Provider>,
     document.getElementById('root')
 );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
