import React, {PureComponent} from 'react';
import Area from '../Charts/Area';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/loader.json';

class AqiInfo extends PureComponent {

  constructor() {
    super();

    this.state = {
      info: null,
      loading: false,
      reading: null,
      hasErrors: false,
      chart: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.info !== props.info) {
      return {
        info: props.info,
        loading: false
      }
    }
    return null
  }

  setStorage(id, type){
      let favourite = this.props.favourite;

      if(!Array.isArray(favourite))
      {
          favourite = [];
      }

      console.log(favourite);

      if(type === 'add')
      {
          if(favourite.indexOf(id) === -1)
          {
              favourite = favourite.concat(id);
          }
      }
      else
      {
          console.log('delete'+id);
          favourite = favourite.filter(item => item !== id);
      }

      console.log(favourite);

      localStorage.setItem('favourite', JSON.stringify(favourite));
      this.props.setFavourite(favourite);
  }

  componentDidMount() {
    this._fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.info.id !== this.props.info.id) {
      this.setState({loading: false});
      this._fetchData();
    }
  }

  _fetchData() {
    fetch(process.env.REACT_APP_API_URL + "/reading_partners/" + this.props.info.id)
      .then(res => res.json())
      .then(json => this.setState({ reading: json, loading: true }))
      .catch(() => this.setState({ hasErrors: true }));
  }

  _getInfo() {
    let parameters = [];

    if (this.state.reading && this.state.reading.readingPartnerAqi) {
      this.state.reading.readingPartnerAqi.forEach((param) => {
        parameters[param.scaleRaw] = {
          aqi: param.paramAqi,
          color: param.aqi ? param.aqi.color : '000000',
          name: param.aqi ? param.aqi.name : '-',
          iconPath: param.aqi ? param.aqi.iconPath : null
        };
      });
    }

    return parameters[this.props.aqi];
  }

  _renderFavouriteButton() {
    let favourite = this.props.favourite;
    let button;

    if (!Array.isArray(favourite) || favourite.indexOf(this.props.info.id) === -1) {
        button = <button onClick={() => this.setStorage(this.state.reading.station.id, 'add')} className="heart grey"><img src={require('../../assets/images/heart-black.png')} alt="heart"/></button>;
    } else {
        button = <button onClick={() => this.setStorage(this.state.reading.station.id, 'remove')} className="heart grey"><img src={require('../../assets/images/heart-red.png')} alt="heart" /></button>;
    }

    return (<div className="favouriteButton">
      {button}
    </div>)
  }


  render() {

    const defaultOptionsAnimation = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
      };

    if(this.state.loading) {
      let aqi = this._getInfo();
      let date = new Date(this.state.reading.paramDate);
      return (
        <div className="popup">
          {this._renderFavouriteButton()}
          <div className="chartButton">
            <button onClick={() => this.setState(prevState => ({chart: !prevState.chart}))} className="chart"><img src={require('../../assets/images/chart.png')} alt="chart" /></button>
          </div>
          <img width={240} src={process.env.REACT_APP_API_IMAGE_URL + aqi.iconPath} alt={aqi.name}/>
          <h3 style={{ color: '#'+aqi.color }}>AQI: {aqi.aqi}</h3>
          <h4 style={{ color: '#'+aqi.color }}>{aqi.name}</h4>
          <div className="reading">
            {this.state.reading.paramAqiPm10?(<h5><span><strong>PM10</strong>: {parseFloat(this.state.reading.paramAqiPm10)}</span> {this.state.reading.paramAqiPm10Percent?(<span>{this.state.reading.paramAqiPm10Percent} %</span>):null}</h5>):null}
            {this.state.reading.paramAqiPm25?(<h5><span><strong>PM2.5</strong>: {parseFloat(this.state.reading.paramAqiPm25)}</span> {this.state.reading.paramAqiPm25Percent?(<span>{this.state.reading.paramAqiPm25Percent} %</span>):null}</h5>):null}
            {this.state.reading.paramAqiO3?(<h5><span><strong>O<sub>3</sub></strong>: {parseFloat(this.state.reading.paramAqiO3)}</span> {this.state.reading.paramAqiO3Percent?(<span>{this.state.reading.paramAqiO3Percent} %</span>):null}</h5>):null}
            {this.state.reading.paramAqiNo2?(<h5><span><strong>NO<sub>2</sub></strong>: {parseFloat(this.state.reading.paramAqiNo2)}</span> {this.state.reading.paramAqiNo2Percent?(<span>{this.state.reading.paramAqiNo2Percent} %</span>):null}</h5>):null}
            {this.state.reading.paramAqiSo2?(<h5><span><strong>SO<sub>2</sub></strong>: {parseFloat(this.state.reading.paramAqiSo2)}</span> {this.state.reading.paramAqiSo2Percent?(<span>{this.state.reading.paramAqiSo2Percent} %</span>):null}</h5>):null}
            {this.state.reading.paramAqiC6h6?(<h5><span><strong>C<sub>6</sub>H<sub>6</sub></strong>: {parseFloat(this.state.reading.paramAqiC6h6)}</span> {this.state.reading.paramAqiC6h6Percent?(<span>{this.state.reading.paramAqiC6h6Percent} %</span>):null}</h5>):null}
            {this.state.reading.paramAqiCo?(<h5><span><strong>CO</strong>: {parseFloat(this.state.reading.paramAqiCo)}</span> {this.state.reading.paramAqiCoPercent?(<span>{this.state.reading.paramAqiCoPercent} %</span>):null}</h5>):null}
            {this.state.reading.paramAqiCo2?(<h5><span><strong>CO<sub>2</sub></strong>: {parseFloat(this.state.reading.paramAqiCo2)}</span> {this.state.reading.paramAqiCo2Percent?(<span>{this.state.reading.paramAqiCo2Percent} %</span>):null}</h5>):null}
          </div>
          <div className="bottom">
            {this.state.reading.station.name}<br/> data pomiaru: {date.toLocaleString("pl-PL")}
          </div>
          {this.state.chart?<Area title={'Wykres godzinowy'} fill={'#444'}/>:<div className="quote" style={{ color: '#'+aqi.color }}>Bywało Lepiej... To nie jest najlepszy dzień na aktywność poza domem</div>}
        </div>
      );
    }
    else{
      return (
        <div className="popup">
            <Lottie
                options={defaultOptionsAnimation}
                height={300}
                width={200}
            />
        </div>
      );
    }
  }

}

export default AqiInfo;
