export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET_ID = 'USER_SET_ID';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_COMPLETE = 'USER_REGISTER_COMPLETE';
export const USER_CONFIRMATION_SUCCESS = 'USER_CONFIRMATION_SUCCESS';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_RECEIVED = 'USER_PROFILE_RECEIVED';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
