import React from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {userRegister} from "../../actions/actions";
import {renderField} from "../../form";

const mapDispatchToProps = {
  userRegister
};

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {agreementRegulations: false, message: false};
  }

  onSubmit(values) {
    return this.props.userRegister(...Object.values(Object.assign(values, {agreementRegulations: this.state.agreementRegulations})))
      .then(() => {
        this.props.reset();
        this.setState({ message: 'Konto zostało utworzone. Kod weryfikacyjny został wysłany mailem'});
      });
  }

  onTermsAcceptedClick(e) {
    this.setState(prevState => ({agreementRegulations: !prevState.agreementRegulations}));
  }

  render() {
    const {handleSubmit, submitting} = this.props;

    return (
      <div className="user-box">
          <Link to="/" className="close">x</Link>
          { this.state.message && <div class="message">{this.state.message}</div> }
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field name="email" label="E-mail:" type="text" component={renderField}/>
            <Field name="password" label="Hasło:" type="password" component={renderField}/>
            <div className="form-group">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox"
                         value={false}
                         onClick={this.onTermsAcceptedClick.bind(this)}/>
                  <label className="form-check-label">Akceptuję regulamin</label>
                </div>
            </div>

            <button type="submit" className="form-button">
                Zarejestruj
            </button>
          </form>

      </div>
    )
  }
}

export default reduxForm({
  form: 'Register'
})(connect(null, mapDispatchToProps)(Register));
