import React, {PureComponent} from 'react';
import FavouriteItem from './FavouriteItem.js';

class Favourite extends PureComponent {

  _renderFavourite() {
    if(this.props.favourite) {
      return this.props.favourite.map((f) => {
          return <FavouriteItem key={f} id={f} aqi={this.props.aqi} goToViewport={this.props.goToViewport}/>
      })
    }
    else{
        return null;
    }
  }


  render() {
      return (
        <div className="favourites">
          {this._renderFavourite()}
        </div>
      );
    }
}

export default Favourite;
