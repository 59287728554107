import React from "react";
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import {userProfileSave} from "../../actions/actions";
import {renderField} from "../../form";
import { Formik, Field, Form } from 'formik';

const mapDispatchToProps = {
  userProfileSave
};

function mapStateToProps(state){
    return{
        userId: state.auth.userId,
        userData: state.auth.userData
    };
}

class Profil extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasErrors: false,
            dataLoad: false,
            dataSave: false,
            userData: [],
            comfort: [],
            specialGroup: [],
            motionActivity: [],
            disease: []
        };
    }

    componentDidMount() {
        Promise.all([
            fetch(process.env.REACT_APP_API_URL + "/comforts"),
            fetch(process.env.REACT_APP_API_URL + "/special_groups"),
            fetch(process.env.REACT_APP_API_URL + "/motion_activities"),
            fetch(process.env.REACT_APP_API_URL + "/diseases")
        ])
        .then(([comfort, special_group, motion_activity, disease]) => {
            return Promise.all([
                comfort.json(),
                special_group.json(),
                motion_activity.json(),
                disease.json()
            ])
        })
        .then(([comfort, special_group, motion_activity, disease]) => {
            this.setState({
                comfort: comfort,
                specialGroup: special_group,
                motionActivity: motion_activity,
                disease: disease
            })
        })
        .then(() => {
            this.setState({ dataLoad: true })
        });
    }

    onSubmit(values) {
        this.setState({ dataSave: true })
        return this.props.userProfileSave(this.props.userId, values)
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.userData !== prevState.userData) {
            return { userData: nextProps.userData };
        }
        else return null;
    }

    render() {
        const { userData } = this.state;

        if(userData && this.state.dataLoad)
        {
            return (
              <div className="user-box-profile">
                  <Link to="/" className="close">x</Link>
                  <Formik
                      initialValues={userData}
                      onSubmit={async (values) => {
                        this.onSubmit(values)
                      }}
                    >
                  <Form>
                  <h3>Twój profil</h3>
                    {this.state.dataSave?<div className="message">Dane zostały zapisane</div>:null}
                    <div className="fields">
                        <div className="half">
                            <div className="row">
                                <h4>Email</h4>
                                <Field name="email" placeholder="Email" width="100%"/>
                            </div>
                            <div className="row">
                                <h4>Płeć</h4>
                                <label>
                                  <Field type="radio" name="gender" value="K" />
                                  Kobieta
                                </label>
                                <label>
                                  <Field type="radio" name="gender" value="M" />
                                  Mężczyzna
                                </label>
                            </div>
                            <div className="row">
                                <h4>Szczególna grupa</h4>
                                <Field as="select" name="specialGroup">
                                    <option value="" key={0}>brak</option>
                                    {this.state.specialGroup.map((s) => {
                                        return <option value={'/api/special_groups/'+s.id} key={s.id}>{s.name}</option>
                                    })}
                                </Field>
                            </div>
                            <div className="row">
                                <h4>Subiektywna ocena samopoczucia</h4>
                                <Field as="select" name="comfort">
                                    <option value="" key={0}>brak</option>
                                    {this.state.comfort.map((c) => {
                                        return <option value={'/api/comforts/'+c.id} key={c.id}>{c.name}</option>
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="half">
                            <div className="row">
                                <h4>Aktywność ruchowa</h4>
                                <Field as="select" name="motionActivity">
                                    {this.state.motionActivity.map((m) => {
                                        return <option value={'/api/motion_activities/'+m.id} key={m.id}>{m.name}</option>
                                    })}
                                </Field>
                            </div>
                            <div className="row">
                                <h4>Choroby</h4>
                                <div className="diseases">
                                    {this.state.disease.map((d) => {
                                        return <label><Field type="checkbox" name="disease" value={'/api/diseases/'+d.id} />{d.name}</label>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="form-button">
                          Zapisz
                        </button>
                    </div>
                  </Form>
                  </Formik>
              </div>
          )
        }
        else
        {
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
