import React, {PureComponent} from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/loader.json';

class FavouriteItem extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reading: null,
      hasErrors: false
    };
  }

  componentDidMount() {
      //console.log(this.props.id);
      fetch(process.env.REACT_APP_API_URL + "/reading_partners/" + this.props.id)
        .then(res => res.json())
        .then(json => this.setState({ reading: json, loading: true }))
        .catch(() => this.setState({ hasErrors: true }));
  }

  _getInfo() {
    let parameters = [];

    if (this.state.reading && this.state.reading.readingPartnerAqi) {
      this.state.reading.readingPartnerAqi.forEach((param) => {
        parameters[param.scaleRaw] = {
          aqi: param.paramAqi,
          color: param.aqi ? param.aqi.color : '000000',
          name: param.aqi ? param.aqi.name : '-',
          iconPath: param.aqi ? param.aqi.iconPath : null
        };
      });
    }

    return parameters[this.props.aqi];
  }

  render() {
      const defaultOptionsAnimation = {
          loop: true,
          autoplay: true,
          animationData: animationData.default,
      };

      let aqi = this._getInfo();

      if(this.state.loading && aqi) {
          //console.log(this.state.reading.station);
          return (
              <span className="favouriteBlock" style={{borderColor: '#'+aqi.color }} onClick={() => this.props.goToViewport(this.state.reading.station)}>
                  {this.state.reading.station.name}
                  <h3>AQI: {aqi.aqi}</h3>
                  <h4>{aqi.name}</h4>
              </span>
          )
      }
      else
      {
          return (
            <span className="favouriteBlock">
                <Lottie
                    options={defaultOptionsAnimation}
                    height={50}
                    width={50}
                />
            </span>
        );
      }
    }
}

export default FavouriteItem;
