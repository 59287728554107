import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form';
import {routerReducer} from "react-router-redux";
import auth from "./reducers/auth";
import registration from "./reducers/registration";

export default combineReducers({
  auth,
  registration,
  router: routerReducer,
  form: formReducer
});
