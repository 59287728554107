import React, {PureComponent} from 'react';

class Panel extends PureComponent {

  constructor() {
    super();

    this.state = {
      showScale: false,
      showMapStyle: false,
      showAqiScale: false,
    }
  }

  _scaleExtend = (e) => {
    this.setState({ showScale: e.currentTarget.dataset.type === 'right'?true:false });
  }

  _buttonsShow = (e) => {
    let name = e.currentTarget.dataset.button;
    let isShow = this.state[name];
    this.setState({ [name]: isShow===true?false:true });
  }

  _renderStyleChangeButton() {
    if(this.props.mapboxStyleList) {
      return Object.keys(this.props.mapboxStyleList).map((key, index) => {
          return <button key={index} className={this.props.mapboxStyle === key?'active':''} onClick={(val) => this.props.onMapboxStyleChange(key)}>{key}</button>
      })
    }
    else{
        return (<div>Wystąpił błąd</div>)
    }
  }

  _renderScale() {
    if(this.props.aqiScalesList) {
      return this.props.aqiScalesList.map((scale) => {
        if (scale.name === this.props.aqi) {
          return scale.aqi.map((record) => {
            return <div className="row" key={record.id}>
              <span className="block" style={{ background: '#'+record.color }}></span>
              <span style={{ color: '#'+record.color }}>
                <img src={process.env.REACT_APP_API_IMAGE_URL + record.iconPath} alt={record.name}/>
                <span className="name">{record.name} ({record.aqiRange})</span>
              </span>
            </div>
          })
        }
        else {
          return null;
        }
      })
    }
    else{
        return (<div>Wystąpił błąd</div>)
    }
  }

  _renderAqiButton() {
    if(this.props.aqiScalesList) {
      return this.props.aqiScalesList.map((scale) => {
          return <button key={scale.id} className={this.props.aqi === scale.name?'active':''} onClick={() => this.props.onAqiChange(scale.name)}>{scale.name}</button>
      })
    }
    else{
        return (<div>Wystąpił błąd</div>)
    }
  }

  render() {

    let scaleClass = 'Scale';
    if(this.state.showScale === true)
    {
      scaleClass += ' show';
    }

    let showMapStyleClass = 'ButtonsList';
    if(this.state.showMapStyle === true)
    {
      showMapStyleClass += ' show';
    }

    let showAqiScaleClass = 'ButtonsList';
    if(this.state.showAqiScale === true)
    {
      showAqiScaleClass += ' show';
    }

    return (
      <div className="Panel">
        <div className={scaleClass}>
          <h2>Jakość<br/>powietrza</h2>
          <div className="ScaleIn">
            <div>
              { this._renderScale() }
            </div>
            <div className="arrows">
              <span href="#" data-type="right" className="arrow arrow-right" onClick={this._scaleExtend}><img src={require('../../assets/images/arrow-right.png')} alt="arrow"/></span>
              <span href="#" data-type="left" className="arrow arrow-left" onClick={this._scaleExtend}><img src={require('../../assets/images/arrow-left.png')} alt="arrow"/></span>
            </div>
          </div>
        </div>
        <div className="Buttons">
          <div className="ButtonsIn">
            <div>
              <button className="StyleButton" data-button="showMapStyle" onClick={this._buttonsShow}><img src={require('../../assets/images/visibility-button.png')} alt="eye"/></button>
            </div>
            <div className={showMapStyleClass}>
              { this._renderStyleChangeButton() }
            </div>
          </div>
        </div>
        <div className="Buttons">
          <div className="ButtonsIn">
            <div>
              <button className="StyleButton" data-button="showAqiScale" onClick={this._buttonsShow}><img src={require('../../assets/images/aqi-button.png')} alt="eye"/></button>
            </div>
            <div className={showAqiScaleClass}>
              { this._renderAqiButton() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Panel;
