import React, {PureComponent} from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import Login from './Login';
import Register from './Register';
import Profil from './Profil';
import './User.css';

class User extends PureComponent {

    render() {
        const {userData, logout, isAuthenticated} = this.props;

        return (
            <div className="User">
                <BrowserRouter>
                    <ul className="user-link">
                        <li className="link">
                        {!isAuthenticated && <NavLink to="/login" activeClassName="active">Zaloguj</NavLink>}
                        </li>
                        {
                            !isAuthenticated &&
                            (
                            <li className="nav-item">
                                <NavLink to="/register" activeClassName="active">Zarejestruj</NavLink>
                            </li>
                            )
                        }
                        {
                            isAuthenticated &&
                            (
                            <li className="nav-item">
                                <NavLink to="/profil" className="nav-link">Mój profil ({userData?userData.email:null})</NavLink>
                                <a href="#" onClick={logout} className="logout">Wyloguj</a>
                            </li>
                            )
                        }
                    </ul>

                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Register} />
                        <Route path="/profil" component={Profil} />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}


export default User;
