import React, { Component } from 'react';
import {VictoryChart, VictoryBar} from "victory";

const chartTheme = {
    axis: {
        style: {
            tickLabels: {
                fill: '#c6df60',
            },
            grid: {
                fill: "none",
                stroke: "none",
                pointerEvents: "painted"
            },
            axis: {
                fill: "transparent",
                stroke: '#c6df60',
                strokeWidth: 1
            },
        },
    },
};

export default class Area extends Component {
    constructor () {
        super();
        this.state = {
            info: null
        };
    }

    render() {
        return (
            <div style={{paddingTop: 10, width: 300}}>
                <p style={{paddingBottom: 10}}>{this.props.title}</p>
                {this.state.info!==null?<p style={{color: 'tomato', fontSize:'14px'}}>{this.state.info}</p>:null}

                    <VictoryChart
                        theme={ chartTheme }
                        domainPadding={10}
                        fill={'#c6df60'}
                        padding={{top: 10, left: 20, right: 20, bottom: 20}}
                        width={this.props.width?this.props.width:300} height={160}
                    >
                    <VictoryBar
                        data={[
                            {x: '11:00', y: 110, info: "godzina 11:00\n AQI: 110"},
                            {x: '12:00', y: 60, info: "godzina 12:00\n AQI: 60"},
                            {x: '13:00', y: 30, info: "godzina 13:00\n AQI: 30"},
                            {x: '14:00', y: 120, info: "godzina 14:00\n AQI: 120"},
                            {x: '15:00', y: 140, info: "godzina 15:00\n AQI: 140"},
                            {x: '16:00', y: 20, info: "godzina 16:00\n AQI: 20"},
                            {x: '17:00', y: 50, info: "godzina 17:00\n AQI: 50"},
                        ]}
                        labels={() => null}
                        events={[
                            {
                                target: "data",
                                eventHandlers: {
                                  onClick: () => {
                                    return [{
                                      mutation: (props) => {
                                          this.setState({
                                              info: props.datum.info
                                          })
                                          console.log(props);

                                          return {style: {fill: "tomato"}};
                                      }
                                    }];
                                  },
                                }
                            }
                        ]}
                        style={{ data:  { fill: "#c6df60", opacity: 1} }}
                        barRatio={0.7}
                        width={this.props.width?this.props.width:400}
                    />
                    </VictoryChart>
            </div>
        )
    }
}
