import React, {Component} from 'react';
import ReactMapGL, {GeolocateControl, Marker, Popup, NavigationControl, FlyToInterpolator} from 'react-map-gl';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/loader.json';
import Panel from './Panel.js';
import AqiInfo from './AqiInfo.js';
import AqiInfoUser from './AqiInfoUser.js';
import Favourite from './Favourite.js';
import User from '../User/User.js';
import './Map.css';

class Map extends Component {

  constructor() {
    super();
    this.state = {
      viewport: {
        width: '100vw',
        height: '100vh',
        latitude: 51.9189046,
        longitude: 19.1343786,
        zoom: 6,
        mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      },
      mapboxStyle: 'standardowy',
      aqi: 'EU AQI',
      logo: 'black',
      hasErrors: false,
      loadMarkers: false,
      markers: [],
      markers_user: [],
      aqiScales: [],
      popupInfo: null,
      favourite: JSON.parse(localStorage.getItem('favourite'))
    };
  }

  mapboxStyle = {
    'standardowy': {
      'style': 'streets-v11',
      'logo': 'black'
    },
    'lekki': {
      'style': 'light-v10',
      'logo': 'black'
    },
    'ciemny': {
      'style': 'dark-v10',
      'logo': 'white'
    },
    'satelita': {
      'style': 'satellite-streets-v11',
      'logo': 'white'
    }
  }

  componentDidMount() {
      Promise.all([
           fetch(process.env.REACT_APP_API_URL + "/reading_partners"),
           fetch(process.env.REACT_APP_API_URL + "/reading_users")
       ])
       .then(([markers, markers_user]) => {
           return Promise.all([
               markers.json(),
               markers_user.json()
           ])
       })
       .then(([markers, markers_user]) => {
           this.setState({ loadMarkers: true, markers: markers, markers_user: markers_user });
       })
       .catch(() => {
           this.setState({ hasErrors: true });
       });

    fetch(process.env.REACT_APP_API_URL + "/scales")
      .then(res => res.json())
      .then(json => this.setState({ aqiScales: json }))
      .catch(() => this.setState({ hasErrors: true }));
  }

  _onMapboxStyleChange = (val) => {this.setState({mapboxStyle: val})};

  _onAqiChange = (val) => {this.setState({aqi: val})};

  _setFavourite = (val) => {this.setState({favourite: val})};

  _renderMarkers() {

      const defaultOptionsAnimation = {
          loop: true,
          autoplay: true
      };

      if(this.state.markers) {
          return this.state.markers.map((marker) => {
            let parameters = [];
            if (marker.readingPartnerAqi) {
              marker.readingPartnerAqi.forEach((param) => {
                parameters[param.scaleRaw] = {
                  aqi: param.paramAqi,
                  color: param.aqi ? param.aqi.color : '000000',
                  name: param.aqi ? param.aqi.name : '-'
                };
              });
            }

            if (parameters[this.state.aqi] && parameters[this.state.aqi].name !== '-') {
              return <Marker key={marker.id} latitude={parseFloat(marker.station.geo_latitud)}
                             longitude={parseFloat(marker.station.geo_longitud)} offsetLeft={0} offsetTop={0}>
                <div className="Marker" style={{ background: '#'+parameters[this.state.aqi].color, boxShadow: '0px 0px 30px #'+parameters[this.state.aqi].color, animationName: 'marker' }} onClick={() => this.setState({popupInfo: marker, popupInfoUser: null})}>{parameters[this.state.aqi].aqi}</div>
              </Marker>
            }
            else {
              return null;
            }
          })
      }
      else{
          return (<div>
              <Lottie
                  options={defaultOptionsAnimation}
                  height={250}
                  width={250}
              />
          </div>)
      }
  }

  _renderMarkersUser() {
      if(this.state.markers_user) {
          return this.state.markers_user.map((marker) => {
            let parameters = [];
            if (marker.readingUserAqi) {
              marker.readingUserAqi.forEach((param) => {
                parameters[param.scaleRaw] = {
                  aqi: param.paramAqi,
                  color: param.aqi ? param.aqi.color : '000000',
                  name: param.aqi ? param.aqi.name : '-'
                };
              });
            }

            if (parameters[this.state.aqi] && parameters[this.state.aqi].name !== '-') {
              return <Marker key={marker.id} latitude={parseFloat(marker.geoLatitud)}
                             longitude={parseFloat(marker.geoLongitud)} offsetLeft={0} offsetTop={0}>
                <div className="Marker" style={{ background: '#'+parameters[this.state.aqi].color, boxShadow: '0px 0px 30px #'+parameters[this.state.aqi].color, animationName: 'marker' }} onClick={() => this.setState({popupInfoUser: marker, popupInfo: null})}>
                    {parameters[this.state.aqi].aqi}
                    <img src={require('../../assets/images/logo.svg')} style={{ width: '25px' }} />
                </div>
              </Marker>
            }
            else {
              return null;
            }
          })
      }
  }

  _renderPopup() {
    const {popupInfo, popupInfoUser} = this.state;
    return (
        <>
          {popupInfo && (
            <Popup
              tipSize={5}
              anchor="top"
              longitude={parseFloat(popupInfo.station.geo_longitud)}
              latitude={parseFloat(popupInfo.station.geo_latitud)}
              closeOnClick={false}
              onClose={() => this.setState({popupInfo: null})}
            >
              <AqiInfo
                info={popupInfo}
                aqi={this.state.aqi}
                favourite={this.state.favourite}
                setFavourite={this._setFavourite}
              />
            </Popup>
        )}

          {popupInfoUser && (
            <Popup
              tipSize={5}
              anchor="top"
              longitude={parseFloat(popupInfoUser.geoLongitud)}
              latitude={parseFloat(popupInfoUser.geoLatitud)}
              closeOnClick={false}
              onClose={() => this.setState({popupInfoUser: null})}
            >
              <AqiInfoUser
                info={popupInfoUser}
                aqi={this.state.aqi}
              />
            </Popup>
        )}
      </>
    );
  }

  _onViewportChange = viewport => this.setState({
      viewport: {...this.state.viewport, ...viewport}
  });

  _goToViewport = ({geo_latitud, geo_longitud}) => {
    this._onViewportChange({
      latitude: parseFloat(geo_latitud),
      longitude: parseFloat(geo_longitud),
      zoom: 13,
      transitionInterpolator: new FlyToInterpolator({speed: 2}),
      transitionDuration: 'auto'
    });
  };

  render() {
    const {isAuthenticated, userData, userLogout} = this.props;

    const defaultOptionsAnimation = {
        loop: true,
        autoplay: true
    };

    return (
      <div>
      <img src={require('../../assets/images/logo_'+this.mapboxStyle[this.state.mapboxStyle]['logo']+'.svg')} className="App-logo" alt="logo" />
      <header className="App-header">
        <User isAuthenticated={isAuthenticated} userData={userData} logout={userLogout}/>
      </header>
        <ReactMapGL
          {...this.state.viewport}
          mapStyle={'mapbox://styles/mapbox/'+this.mapboxStyle[this.state.mapboxStyle]['style']}
          onViewportChange={this._onViewportChange}
          doubleClickZoom={false}
        >

          <Panel
            mapboxStyle={this.state.mapboxStyle}
            mapboxStyleList={this.mapboxStyle}
            aqi={this.state.aqi}
            aqiScalesList={this.state.aqiScales}
            onMapboxStyleChange={this._onMapboxStyleChange}
            onAqiChange={this._onAqiChange}
          />
          <Favourite
            favourite={this.state.favourite}
            goToViewport={this._goToViewport}
            aqi={this.state.aqi}
          />
          <GeolocateControl
            className="GeolocateControl"
            positionOptions={{enableHighAccuracy: true}}
            trackUserLocation={false}
          />
          <div className="NavigationControl">
              <NavigationControl
                showCompass={false}
              />
          </div>
          { this.state.loadMarkers
              ?
              <>
                {this._renderMarkers()}
                {this._renderMarkersUser()}
                {this._renderPopup()}
              </>
              :
              <div>
                  <Lottie
                      options={defaultOptionsAnimation}
                      height={250}
                      width={250}
                  />
              </div>
          }
        </ReactMapGL>
      </div>
    );
  }
}


export default Map;
