import React from 'react';
import './App.css';
import Map from '../Map/Map.js';
import {connect} from "react-redux";
import {requests} from "../../agent";
import {userLogout, userProfileFetch, userSetId} from "../../actions/actions";

const mapStateToProps = state => ({
  ...state.auth
});

const mapDispatchToProps = {
  userProfileFetch, userSetId, userLogout
};

class App extends React.Component {

    constructor(props) {
      super(props);
      const token = window.localStorage.getItem('jwtToken');

      if (token) {
        requests.setToken(token);
      }
    }

    componentDidMount() {
      const userId = window.localStorage.getItem('userId');
      const {userSetId} = this.props;

      if (userId) {
        userSetId(userId);
      }
    }

    componentDidUpdate(prevProps) {
      const {userId, userData, userProfileFetch} = this.props;

      if (prevProps.userId !== userId && userId !== null && userData === null) {
        userProfileFetch(userId);
      }
    }

    render() {
        const {isAuthenticated, userData, userLogout} = this.props;
        console.log(isAuthenticated);
        console.log('ok');
        return (
            <div className="App">
                <Map isAuthenticated={isAuthenticated} userData={userData} userLogout={userLogout}/>
            </div>
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
